import { InboxDocumentLocation } from '../../../shared/foreground/inboxDocumentLocation';
import type { DocumentLocation, SplitByKey } from '../../../shared/types';
import { FeedDocumentLocation } from '../../../shared/types';
import { safeDecodeURIComponent } from '../../../shared/utils/safeDecodeURIComponent';

export const getPaths = (pathName: string) => pathName.slice(1).split('/');

/*
  /:documentLocation
  Unfortunately we can't use documentLocations from state here
*/
export const isLibraryView = (pathName: string) =>
  Object.values(InboxDocumentLocation)
    .map((documentLocation) => `/${documentLocation}`)
    .includes(pathName);

export const isDocumentPathname = (pathName: string) => /\/read\/?/.test(pathName);

// /feed/:feedDocumentLocation
export const isFeedView = (pathName: string) =>
  Object.values(FeedDocumentLocation)
    .map((status) => `/feed/${status}`)
    .includes(pathName);

// /filter/:query
export const isFiltersView = (pathName: string) => pathName.startsWith('/filter/');

// /search
export const isSearchView = (pathName: string) => pathName.startsWith('/search');

// /feed/sources
export const isFeedsSourcesList = (pathName: string) => pathName.startsWith('/feed/sources');

// /tags
export const isTagsList = (pathName: string) => pathName.startsWith('/tags');

// /filter/:query
export const getFilterViewQueryFromPathname = (pathName: string) => {
  const [, secondPath] = getPaths(pathName);

  if (isFiltersView(pathName)) {
    return safeDecodeURIComponent(secondPath);
  }

  return undefined;
};

// /filter/:query/split/:splitByKey/:splitByValue
export const getSplitByKeyFromPathname = (pathName: string) => {
  if (!isFiltersView(pathName)) {
    return undefined;
  }

  const splitBy = pathName.split('/split/')[1];

  if (!splitBy) {
    return undefined;
  }

  const [splitByKey] = splitBy.split('/');

  return splitByKey as SplitByKey;
};

// /filter/:query?splittingBy=${splitByKey}
export const getSplittingByKeyFromLocation = (location: Location) => {
  const urlSearchParams = new URLSearchParams(location.search);
  return (urlSearchParams.get('splittingBy') as SplitByKey) || undefined;
};

// /filter/:query/split/:splitByKey/:splitByValue
export const getSplitByValueFromPathname = (pathName: string) => {
  if (!isFiltersView(pathName)) {
    return undefined;
  }

  const splitBy = pathName.split('/split/')[1];

  if (!splitBy) {
    return undefined;
  }

  const [, splitByValue] = splitBy.split('/');

  return splitByValue;
};

// /:documentLocation
// /feed
export const getCurrentDocumentLocationFromPathname = (pathName: string) => {
  const [firstPath] = getPaths(pathName);

  if (isLibraryView(pathName) || isFeedView(pathName)) {
    return firstPath as DocumentLocation;
  }

  return undefined;
};

// /feed/:feedDocumentLocation
export const getFeedDocumentLocationFromPathname = (pathName: string) => {
  const [, secondPath] = getPaths(pathName);

  if (isFeedView(pathName)) {
    return secondPath as FeedDocumentLocation;
  }

  return undefined;
};

export const shouldShowBulkActionsMenu = (pathName: string) => {
  return isLibraryView(pathName) || isFeedView(pathName) || isFiltersView(pathName);
};

export const getSplitByKeyOrSplittingByKeyFromLocation = (location: Location) => {
  const pathName = location.pathname;
  const splitBy = getSplitByKeyFromPathname(pathName);
  const splittingBy = getSplittingByKeyFromLocation(location);
  return splitBy ?? splittingBy;
};

export const readerViewUrl = /((\/)[a-z])*(\/)read(\/)/;
export const isReaderViewUrl = (pathname: string) => readerViewUrl.test(pathname);
export const isNotebookViewPath = (pathname: string) => pathname.startsWith('/notebook/');
