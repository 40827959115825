// shift+tab => Shift+Tab
// Esc => Esc
// t => T
import capitalize from '../../../../shared/utils/capitalize';
import { os } from '../../../../shared/utils/environment';
import { replaceBadKeyAliases } from './aliasesWeReplace';

const isMac = os.name.includes('Mac');
const CmdOrCtrl = isMac ? 'cmd' : 'ctrl';

const shortcutsWords = [
  'backquote',
  'shift',
  'ctrl',
  'alt',
  'cmd',
  'option',
  'space',
  'esc',
  'pageup',
  'pagedown',
  'down',
  'up',
  'enter',
  'tab',
  'home',
  'end',
  'meta',
  'backspace',
  'delete',
];

// Shortcut could be multiple shortcuts E.g `z,Cmd+z`
// That's why we make sure to return the first one as display name
export const getDisplayShortcutKeys = (shortcut: string | string[] | undefined): string => {
  if (!shortcut) {
    return '';
  }

  let firstShortcut: string;

  if (typeof shortcut === 'string') {
    firstShortcut = shortcut;
  } else {
    firstShortcut = shortcut[0];
  }

  if (!firstShortcut) {
    return '';
  }

  firstShortcut = replaceBadKeyAliases(firstShortcut)
    .toLocaleLowerCase()
    .split('+')
    .map((key) => {
      if (key === 'equal') {
        return '=';
      }

      if (key === 'period') {
        return '.';
      }

      if (key === 'comma') {
        return ',';
      }

      if (key === 'meta') {
        return CmdOrCtrl;
      }

      if (key === 'alt' && isMac) {
        return 'option';
      }

      if (key === 'up') {
        return '↑';
      }

      if (key === 'down') {
        return '↓';
      }

      if (key === 'left') {
        return '←';
      }

      if (key === 'right') {
        return '→';
      }

      if (key === '#') {
        return '\\';
      }

      return key;
    })
    .join(' ');

  if (firstShortcut === 'shift ?') {
    return '?';
  }

  if (firstShortcut === 'shift -') {
    return 'Shift -';
  }

  if (firstShortcut === 'shift add') {
    return 'Shift +';
  }

  const upperCaseShortcut = firstShortcut.toLocaleUpperCase();

  return shortcutsWords.reduce((newValue, word) => {
    const upperCaseWord = word.toLocaleUpperCase();
    const capitalizeWord = capitalize(word);
    return newValue.replace(upperCaseWord, capitalizeWord);
  }, upperCaseShortcut);
};

export const replaceMetaByCmdOrCtrl = (keys: string): string => {
  return keys.replace(/meta/g, isMac ? 'cmd' : 'ctrl');
};

export const replaceKeyByPlatform = (keys: string): string => {
  return keys.replace(/CmdOrCtrl/g, isMac ? 'meta' : 'ctrl');
};
