import eventEmitter from '../../../shared/foreground/eventEmitter';
import { RightSidebarVisiblePanel } from '../../../shared/types';
import delay from '../../../shared/utils/delay';
import { hideRightSidebar } from '../stateUpdaters/sidebars';

export default async function focusDocumentNoteField(userInteraction = 'unknown') {
  hideRightSidebar(false, { userInteraction });
  await delay(1);
  eventEmitter.emit('document-sidebar:setVisiblePanel', RightSidebarVisiblePanel.DocumentNotebook);
  await delay(5);
  eventEmitter.emit('focus-doc-note');
}
